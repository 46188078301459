.product {
  width: 350px;
  height: 500px;
  border: 2px solid #20ce88;
  border-radius: 5px;
  margin: 1rem;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  transition: all 0.2s ease;
  position: relative;

  // @media #{$laptop-device} {
  //   height: 500px;
  // }
  // @media #{$lg-layout} {
  //   height: 500px;
  // }
  // @media #{$md-layout} {
  //   height: 450px;
  // }
  // @media #{$sm-layout} {
  //   height: 400px;
  // }

  .thumbnail {
    // position: relative;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &.labelling-adh {
      background-image: url(/assets/images/products/labelling-adh.svg);
    }
    &.wood-adh {
      background-image: url(/assets/images/products/wood-adh.svg);
    }
    &.metal-chem {
      background-image: url(/assets/images/products/metal-chem.svg);
    }
  }
  &:hover {
    transform: scale(1.05);
  }
}

.flex.wrap.center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#particles-js canvas {
  width: 100%;
  height: 100%;
  display: block;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: opacity 0.8s ease, -webkit-transform 1.4s ease;
  transition: opacity 0.8s ease, transform 1.4s ease;
}

#particles-js-1,
#particles-js-2,
#particles-js-3,
#particles-js-4 {
  // width: 100%;
  // height: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
} 
