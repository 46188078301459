.address {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.address-item {
  font-size: 17px;
}

.form-wrapper input,
.form-wrapper textarea {
  font-family: Poppins;
}
